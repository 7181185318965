import React from 'react'
import * as styles from './layout.module.css'

import Header from '../common/header'
import Footer from '../common/footer'

export default function Layout({ children }) {
  return (
    <main className={styles.siteContainer}>
      <Header/>

      {children}

      <Footer/>
    </main>
  )
}
