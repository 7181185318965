import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import favicon from "../../images/favicon.png"

function Metadata({ description, lang, meta, title, socialTitle }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            twitterHandle
            authorTwitterHandle
            siteUrl
          }
        }
      }
    `,
  )
  const metaDescription = description || site.siteMetadata.description
  const socialPreview = `${site.siteMetadata.siteUrl}/social-preview.png`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: socialTitle || title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: socialPreview,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.authorTwitterHandle,
        },
        {
          name: `twitter:site`,
          content: site.siteMetadata.twitterHandle,
        },
        {
          name: `twitter:title`,
          content: socialTitle || title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: socialPreview,
        },
      ].concat(meta)}
      link={[
        { rel: "icon", type: "image/png", href: `${favicon}` },
        { rel: "shortcut icon", type: "image/png", href: `${favicon}` },
      ]}
    />
  )
}

Metadata.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Metadata.propTypes = {
  description: PropTypes.string,
  featuredImage: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  socialTitle: PropTypes.string
}

export default Metadata
