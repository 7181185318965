import React from 'react'
import { Link } from 'gatsby'

import HeaderNav from '../navigation/header-nav'
import Divider from './divider'

import logo from '../../images/logo.svg'
import * as styles from './header.module.css'

export default function Header() {
  return (
    <header className={styles.siteHeader}>
      <div>
        <Link className={styles.logoLink} to={'/'}>
          <img className={styles.logo} src={logo}/>
        </Link>
      </div>

      <HeaderNav/>

      <Divider/>
    </header>
  )
}
