import React from 'react'
import { Link } from 'gatsby'

import * as styles from './header-nav.module.css'

export default function HeaderNav() {
  return (
    <nav className={styles.headerNav}>
      <ul>
        <Link to={"/"}>
          <li>Clips</li>
        </Link>
        <li>/</li>
        <a href="/rss.xml" target={"_blank"} rel={"noopener"}>
          <li>Feed</li>
        </a>
        <li>/</li>
        <a href="https://twitter.com/chowdhuryrahul" target={"_blank"} rel={"noreferrer noopener nofollow"}>
          <li>Twitter</li>
        </a>
      </ul>
    </nav>
  )
}
