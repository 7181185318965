import React from 'react'

import * as styles from './footer.module.css'

import logoHulry from '../../images/logo-hulry.svg'

export default function Footer() {
  return (
    <footer className={styles.siteFooter}>
      <p className={styles.credits}>Brought to you by</p>
      <a href={'https://hulry.com'} target={'_blank'}>
        <img src={logoHulry}/>
      </a>

      <p className={styles.description}>Clipped is a random collection of interesting clips from around the web.</p>
    </footer>
  )
}
