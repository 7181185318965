import React from 'react'
import { Link } from 'gatsby'
import { format } from 'date-fns'

import cut from '../../images/cut.svg'
import * as styles from './post-item.module.css'

import Divider from '../common/divider'

export default function PostItem(props) {
  const postDate = format(new Date(props.created), 'MMMM d, y')
  const permalinkTitle = `Permalink for ${props.title}`

  return (
    <article className={styles.clippedPost}>
      <a href={props.link} target={'_blank'} rel={'noreferrer noopener nofollow'}>
        <h1><span>{props.title}</span></h1>
      </a>

      <time>{postDate}</time>

      {props.showPermalink && (
        <Link className={styles.permalinkContent} to={props.slug}>
          <img className={styles.permalink} src={cut} title={permalinkTitle}/>
        </Link>
      )}

      <div className={styles.postContent}
           dangerouslySetInnerHTML={{ __html: props.content }}/>

      <div className={styles.postDivider}>
        <Divider/>
      </div>
    </article>
  )
}
